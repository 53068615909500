/*
 * @Author: harley
 * @Date: 2024-03-13 17:02:27
 * @LastEditors: harley
 * @Description:
 */
// 获取推文列表

// 搜索接口
import { manualFetch } from '~/api/base';
export const getHashTagData = (params) => {
  return manualFetch('/_api/v1/search', {
    method: 'get',
    params,
  });
};
